.carousel-inner {
    display: flex;
    flex-direction: column;
  
  }
  
  .carousel-item {
    transition: transform 0.6s ease; /* Smooth transition effect */
    /* height: 100vh; Each slide should take full viewport height */
  }
  
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right,
  .carousel-item.active {
    transform: translateY(0); /* Keep the active slide in view without transformation */
  }
  
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translateY(100%);
  }
  
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translateY(100%);
  }
  
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translateY(-100%);
  }

  .carousel-control-prev{
    transform: translateX(-70%);
  }

  .carousel-control-prev-icon {
    background-image: url('../public/images/prev.webp');
  }

  .carousel-control-next{
    transform: translateX(70%);
  }
  .carousel-control-next-icon{
    background-image: url('../public/images/next.webp');
  }
  
  
  